export function MatlabVisualization(props) {
  const url_grafico = `https://thingspeak.com/apps/matlab_visualizations/${props.canale}/`;

  return (
    <object
      id={props.id}
      type="text/html"
      width={props.width}
      height={props.height}
      style={{ overflow: "auto", border: "1px ridge blue" }}
      data={url_grafico}
    />
  );
}
