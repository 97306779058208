import { Grafico } from "./Grafico";
import { MatlabVisualization } from "./MatlabVisualization";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export function PompaPedrollo(props) {
  return (
    <section id="pompa-pedrollo" className="thingspeak-plot-section">
      {/* Grafici/Dati del canale */}
      <h2>Pompa Pedrollo</h2>

      <Container>
        <Row xs={1} lg={2}>
          <Col>
            <h3>
              Funzionamento
              <br />
            </h3>
            <Grafico
              id="pompa-pedrollo-funzionamento"
              canale={1244762}
              tipo={"charts"}
              campo={1}
              giorni={props.days}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>
              Misurazione
              <br />
            </h3>
            <Grafico
              id="pompa-pedrollo-misurazione"
              canale={1244762}
              tipo={"charts"}
              campo={2}
              giorni={props.days}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>
              Ultima lettura
              <br />
            </h3>
            <Grafico
              id="pompa-pedrollo-ultima-lettura"
              canale={1244762}
              tipo={"widgets"}
              campo={409686}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>
              Ore al giorno
              <br />
            </h3>
            <MatlabVisualization
              id="pompa-pedrollo-ore-al-giorno"
              canale={444173}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>
              Funzionamento per settimana
              <br />
            </h3>
            <MatlabVisualization
              id="pompa-pedrollo-per-settimana"
              canale={444174}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>
              Funzionamento ultimo mese
              <br />
            </h3>
            <MatlabVisualization
              id="pompa-pedrollo-ultimo-mese"
              canale={444175}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>
              Funzionamento ultimo anno
              <br />
            </h3>
            <MatlabVisualization
              id="pompa-pedrollo-ultimo-anno"
              canale={444176}
              width="450"
              height="260"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
