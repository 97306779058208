/**
 * Component for hamburger menu item, and onClick functions associated.
 * name: menu item name to be displayed
 */
export function MenuItem(props) {
  function clickMenuItem(event) {
    const element_id = event.target.id;

    // Mostra solo la sezione richiesta
    switch (element_id) {
      case "menu-sommario":
        props.setShowComponent("sommario");
        break;

      case "menu-idroponia":
        props.setShowComponent("idroponia");
        break;

      case "menu-incubatore":
        props.setShowComponent("incubatore");
        break;

      case "menu-contapersone":
        props.setShowComponent("contapersone");
        break;

      case "menu-pompa-lorentz":
        props.setShowComponent("pompa-lorentz");
        break;

      case "menu-pompa-pedrollo":
        props.setShowComponent("pompa-pedrollo");
        break;

      case "menu-pompa-pedrollo-cumiana":
        props.setShowComponent("pompa-pedrollo-cumiana");
        break;

      case "menu-impianto-fotovoltaico-tanzania":
        props.setShowComponent("impianto-fotovoltaico-tanzania");
        break;

      case "menu-essiccatore-solare":
        props.setShowComponent("essiccatore-solare");
        break;

      case "menu-frigo-solare":
        props.setShowComponent("frigo-solare");
        break;

      default:
        break;
    }
  }

  return (
    <div id={props.id} onClick={clickMenuItem}>
      {props.name}
    </div>
  );
}
