import { Grafico } from "./Grafico";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MatlabVisualization } from "./MatlabVisualization";

export function ImpiantoFotovoltaicoTanzania(props) {
  return (
    <section
      id="impianto-fotovoltaico-tanzania"
      className="thingspeak-plot-section"
    >
      {/* Grafici/Dati del canale */}
      <h2>Impianto Fotovoltaico Tanzania</h2>

      <Container>
        <Row xs={1} lg={2}>
          <Col>
            <h3>Alimentazione casa Baba Inox</h3>
            <Grafico
              id="pompa-msp-attivita"
              canale={1726924}
              tipo={"charts"}
              campo={1}
              giorni={props.days}
              formato={"column"}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>Attività trasmettitore</h3>
            <Grafico
              id="pompa-msp-attivita-trasmettitore"
              canale={1726924}
              tipo={"charts"}
              campo={2}
              giorni={props.days}
              formato={"column"}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>Ore al giorno</h3>
            <MatlabVisualization
              id="pompa-msp-ore-al-giorno"
              canale={474498}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>Funzionamento per settimana</h3>
            <MatlabVisualization
              id="pompa-msp-per-settimana"
              canale={474499}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>Funzionamento ultimo mese</h3>
            <MatlabVisualization
              id="pompa-msp-ultimo-mese"
              canale={474500}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>Funzionamento ultimo anno</h3>
            <MatlabVisualization
              id="pompa-msp-ultimo-anno"
              canale={474501}
              width="450"
              height="260"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
