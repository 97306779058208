import axios from "axios";
import {
  setBoieroContapassaggi1,
  setBoieroContapassaggi2,
  setBoieroEssiccatoreTemperatura,
  setBoieroEssiccatoreUmidita,
  setBoieroFrigoSolare,
  setDownloadActive,
  setFarmaci,
  setFotovoltaico,
  setLorentz,
  setPedrollo,
  setPedrolloMSP,
  setVaschePesci,
  setVentoEO,
  setVentoNS,
} from "./sommarioSlice";

function parseHoursPerMonths(response, year, fieldNumber) {
  // Utility function to parse hours per calendar months from ThingSpeak API response
  let months = Array(12).fill(0);
  let last7Days = 0;
  let last30Days = 0;

  response.data["feeds"].forEach((d) => {
    // Calculate time difference
    const date = new Date(d["created_at"]);
    const timeDiff = (Date.now() - date) / (1000 * 60 * 60 * 24);

    // Bin data for table
    if (timeDiff <= 7) last7Days += Number(d[`field${fieldNumber}`]);

    if (timeDiff <= 30) last30Days += Number(d[`field${fieldNumber}`]);

    if (date.getFullYear() === year)
      months[date.getMonth()] += Number(d[`field${fieldNumber}`]);
  });

  return {
    last7Days: last7Days / 3600,
    last30Days: last30Days / 3600,
    months: months.map((seconds) => seconds / 3600),
  };
}

function parseMaxPerMonths(response, year, fieldNumber) {
  // Utility function to parse hours per calendar months from ThingSpeak API response
  let months = Array(12).fill(0);
  let last7Days = 0;
  let last30Days = 0;

  response.data["feeds"].forEach((d) => {
    // Calculate time difference
    const date = new Date(d["created_at"]);
    const timeDiff = (Date.now() - date) / (1000 * 60 * 60 * 24);

    // Bin data for table
    if (timeDiff <= 7)
      last7Days = Math.max(last7Days, Number(d[`field${fieldNumber}`]));

    if (timeDiff <= 30)
      last30Days = Math.max(last30Days, Number(d[`field${fieldNumber}`]));

    if (date.getFullYear() === year)
      months[date.getMonth()] = Math.max(
        months[date.getMonth()],
        Number(d[`field${fieldNumber}`])
      );
  });

  return {
    last7Days: last7Days,
    last30Days: last30Days,
    months: months,
  };
}

function parseSumPerMonths(response, year, fieldNumber) {
  // Utility function to parse hours per calendar months from ThingSpeak API response
  let months = Array(12).fill(0);
  let last7Days = 0;
  let last30Days = 0;

  response.data["feeds"].forEach((d) => {
    // Calculate time difference
    const date = new Date(d["created_at"]);
    const timeDiff = (Date.now() - date) / (1000 * 60 * 60 * 24);

    // Bin data for table
    if (timeDiff <= 7) last7Days += Number(d[`field${fieldNumber}`]);

    if (timeDiff <= 30) last30Days += Number(d[`field${fieldNumber}`]);

    if (date.getFullYear() === year)
      months[date.getMonth()] += Number(d[`field${fieldNumber}`]);
  });

  return {
    last7Days: last7Days,
    last30Days: last30Days,
    months: months,
  };
}

function parseFarmaci(response, year) {
  let ret = {};
  const currentDate = new Date();

  response.data.forEach((d) => {
    const store = d.Store;
    const dataYear = Number(d.Mese.split("-")[0]);
    const dataMonth = Number(d.Mese.split("-")[1]);

    if (dataYear === year) {
      if (!(store in ret)) {
        ret[store] = {};
        ret[store].months = Array(12).fill(0);
        ret[store].last30Days = 0;
      }

      ret[store].months[dataMonth - 1] =
        Number(d["Destinazione finale"]) + Number(d["Ad altro magazzino"]);

      if (dataMonth - 1 === currentDate.getMonth())
        ret[store].last30Days =
          Number(d["Destinazione finale"]) + Number(d["Ad altro magazzino"]);
    }
  });

  return ret;
}

export function downloadThingSpeakData(year, abortControllerRef) {
  return (dispatch) => {
    // Abort previous requests
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      console.log("Aborting requests...");
    }
    abortControllerRef.current = new AbortController();

    dispatch(setDownloadActive(false)); // Disable download

    fetchLorentz(dispatch, year, abortControllerRef.current);
    fetchPedrollo(dispatch, year, abortControllerRef.current);
    fetchFotovoltaico(dispatch, year, abortControllerRef.current);
    fetchVentoNS(dispatch, year, abortControllerRef.current);
    fetchVentoEO(dispatch, year, abortControllerRef.current);
    fetchBoieroEssiccatoreTemperatura(
      dispatch,
      year,
      abortControllerRef.current
    );
    fetchBoieroEssiccatoreUmidita(dispatch, year, abortControllerRef.current);
    fetchBoieroFrigoSolare(dispatch, year, abortControllerRef.current);
    fetchBoieroContapassaggi1(dispatch, year, abortControllerRef.current);
    fetchBoieroContapassaggi2(dispatch, year, abortControllerRef.current);
    fetchPedrolloMSP(dispatch, year, abortControllerRef.current);
    fetchVaschePesci(dispatch, year, abortControllerRef.current);
    fetchFarmaci(dispatch, year, abortControllerRef.current);

    abortControllerRef.current = null;
  };
}

export function fetchLorentz(dispatch, year, abortController) {
  // Pompa Lorentz Ntchangue
  const urlLorentz = `https://api.thingspeak.com/channels/879658/feed.json?start=${year}-01-01%2000:00:00&end=${year}-12-31%2023:59:59`;
  dispatch(setLorentz(null));

  axios
    .get(urlLorentz, {
      signal: abortController.signal,
    })
    .then(
      (response) => {
        const updateObj = parseHoursPerMonths(response, year, 1);
        dispatch(setLorentz(updateObj));
      },
      () => {
        console.log(`Error retrieving ${urlLorentz}`);
      }
    );
}

export function fetchPedrollo(dispatch, year, abortController) {
  // Pompa Pedrollo Ntchangue
  const urlPedrollo = `https://api.thingspeak.com/channels/1244762/feed.json?feed.json?start=${year}-01-01%2000:00:00&end=${year}-12-31%2023:59:59`;
  dispatch(setPedrollo(null));

  axios
    .get(urlPedrollo, {
      signal: abortController.signal,
    })
    .then(
      (response) => {
        const updateObj = parseHoursPerMonths(response, year, 1);
        dispatch(setPedrollo(updateObj));
      },
      () => {
        console.log(`Error retrieving ${urlPedrollo}`);
      }
    );
}

export function fetchFotovoltaico(dispatch, year, abortController) {
  // Fotovoltaico Mkiu
  const urlFotovoltaico = `https://api.thingspeak.com/channels/1726924/feed.json?feed.json?start=${year}-01-01%2000:00:00&end=${year}-12-31%2023:59:59`;
  dispatch(setFotovoltaico(null));

  axios
    .get(urlFotovoltaico, {
      signal: abortController.signal,
    })
    .then(
      (response) => {
        const updateObj = parseHoursPerMonths(response, year, 1);
        dispatch(setFotovoltaico(updateObj));
      },
      () => {
        console.log(`Error retrieving ${urlFotovoltaico}`);
      }
    );
}

export function fetchVentoNS(dispatch, year, abortController) {
  // Fotovoltaico Mkiu
  const urlVentoNS = `https://api.thingspeak.com/channels/1570323/field/7.json?start=${year}-01-01%2000:00:00&end=${year}-12-31%2023:59:59`;
  dispatch(setVentoNS(null));

  axios
    .get(urlVentoNS, {
      signal: abortController.signal,
    })
    .then(
      (response) => {
        const updateObj = parseMaxPerMonths(response, year, 7);
        dispatch(setVentoNS(updateObj));
      },
      () => {
        console.log(`Error retrieving ${urlVentoNS}`);
      }
    );
}

export function fetchVentoEO(dispatch, year, abortController) {
  // Fotovoltaico Mkiu
  const urlVentoEO = `https://api.thingspeak.com/channels/1570323/field/8.json?start=${year}-01-01%2000:00:00&end=${year}-12-31%2023:59:59`;
  dispatch(setVentoEO(null));

  axios
    .get(urlVentoEO, {
      signal: abortController.signal,
    })
    .then(
      (response) => {
        const updateObj = parseMaxPerMonths(response, year, 8);
        dispatch(setVentoEO(updateObj));
      },
      () => {
        console.log(`Error retrieving ${urlVentoEO}`);
      }
    );
}

export function fetchBoieroEssiccatoreTemperatura(
  dispatch,
  year,
  abortController
) {
  // Essiccatore Boiero temperatura massima
  const urlEssiccatoreTemperatura = `https://api.thingspeak.com/channels/1612409/field/3.json?start=${year}-01-01%2000:00:00&end=${year}-12-31%2023:59:59`;
  dispatch(setBoieroEssiccatoreTemperatura(null));

  axios
    .get(urlEssiccatoreTemperatura, {
      signal: abortController.signal,
    })
    .then(
      (response) => {
        const updateObj = parseMaxPerMonths(response, year, 3);
        dispatch(setBoieroEssiccatoreTemperatura(updateObj));
      },
      () => {
        console.log(`Error retrieving ${urlEssiccatoreTemperatura}`);
      }
    );
}

export function fetchBoieroEssiccatoreUmidita(dispatch, year, abortController) {
  // Essiccatore Boiero umidita massima
  const urlEssiccatoreUmidita = `https://api.thingspeak.com/channels/1612409/field/4.json?start=${year}-01-01%2000:00:00&end=${year}-12-31%2023:59:59`;
  dispatch(setBoieroEssiccatoreUmidita(null));

  axios
    .get(urlEssiccatoreUmidita, {
      signal: abortController.signal,
    })
    .then(
      (response) => {
        const updateObj = parseMaxPerMonths(response, year, 4);
        dispatch(setBoieroEssiccatoreUmidita(updateObj));
      },
      () => {
        console.log(`Error retrieving ${urlEssiccatoreUmidita}`);
      }
    );
}

export function fetchBoieroFrigoSolare(dispatch, year, abortController) {
  const urlFrigoSolare = `https://api.thingspeak.com/channels/1612409/field/5.json?start=${year}-01-01%2000:00:00&end=${year}-12-31%2023:59:59`; //FIXME: controllare campo, forse non corretto
  dispatch(setBoieroFrigoSolare(null));

  axios
    .get(urlFrigoSolare, {
      signal: abortController.signal,
    })
    .then(
      (response) => {
        const updateObj = parseHoursPerMonths(response, year, 5); //FIXME: controllare campo, forse non corretto
        dispatch(setBoieroFrigoSolare(updateObj));
      },
      () => {
        console.log(`Error retrieving ${urlFrigoSolare}`);
      }
    );
}

export function fetchBoieroContapassaggi1(dispatch, year, abortController) {
  const urlContapassaggi1 = `https://api.thingspeak.com/channels/1612407/field/1.json?start=${year}-01-01%2000:00:00&end=${year}-12-31%2023:59:59`;
  dispatch(setBoieroContapassaggi1(null));

  axios
    .get(urlContapassaggi1, {
      signal: abortController.signal,
    })
    .then(
      (response) => {
        const updateObj = parseSumPerMonths(response, year, 1);
        dispatch(setBoieroContapassaggi1(updateObj));
      },
      () => {
        console.log(`Error retrieving ${urlContapassaggi1}`);
      }
    );
}

export function fetchBoieroContapassaggi2(dispatch, year, abortController) {
  const urlContapassaggi2 = `https://api.thingspeak.com/channels/1612407/field/4.json?start=${year}-01-01%2000:00:00&end=${year}-12-31%2023:59:59`;
  dispatch(setBoieroContapassaggi2(null));

  axios
    .get(urlContapassaggi2, {
      signal: abortController.signal,
    })
    .then(
      (response) => {
        const updateObj = parseSumPerMonths(response, year, 4);
        dispatch(setBoieroContapassaggi2(updateObj));
      },
      () => {
        console.log(`Error retrieving ${urlContapassaggi2}`);
      }
    );
}

export function fetchPedrolloMSP(dispatch, year, abortController) {
  // Pompa Pedrollo Ntchangue
  const urlPedrolloMSP = `https://api.thingspeak.com/channels/896318/feed.json?feed.json?start=${year}-01-01%2000:00:00&end=${year}-12-31%2023:59:59`;
  dispatch(setPedrolloMSP(null));

  axios
    .get(urlPedrolloMSP, {
      signal: abortController.signal,
    })
    .then(
      (response) => {
        const updateObj = parseHoursPerMonths(response, year, 1);
        dispatch(setPedrolloMSP(updateObj));
      },
      () => {
        console.log(`Error retrieving ${urlPedrolloMSP}`);
      }
    );
}

export function fetchVaschePesci(dispatch, year, abortController) {
  // Pompa Vasche Pesci
  const urlVaschePesci = `https://api.thingspeak.com/channels/2051259/feed.json?feed.json?start=${year}-01-01%2000:00:00&end=${year}-12-31%2023:59:59`;
  dispatch(setVaschePesci(null));

  axios
    .get(urlVaschePesci, {
      signal: abortController.signal,
    })
    .then(
      (response) => {
        const updateObj = parseHoursPerMonths(response, year, 1);
        dispatch(setVaschePesci(updateObj));
      },
      () => {
        console.log(`Error retrieving ${urlVaschePesci}`);
      }
    );
}

export function fetchFarmaci(dispatch, year, abortController) {
  // Farmaci, piattaforma FaRo
  const urlFarmaci = `https://www.condorinformatique.com/faro/FaRo_statMonth.php?fnz=JSON`;
  dispatch(setFarmaci(null));

  axios
    .get(urlFarmaci, {
      signal: abortController.signal,
    })
    .then(
      (response) => {
        const updateObj = parseFarmaci(response, year);
        dispatch(setFarmaci(updateObj));
      },
      () => {
        console.log(`Error retrieving ${urlFarmaci}`);
      }
    );
}
