import { Grafico } from "./Grafico";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export function Incubatore(props) {
  return (
    <section id="incubatore" className="thingspeak-plot-section">
      {/* Grafici/Dati del canale */}
      <h2>Incubatore solare</h2>

      <Container>
        <Row xs={1} lg={2}>
          <Col>
            <h3>Temperatura esterna</h3>
            <Grafico
              id="incubatore-temperatura-interna"
              canale={1578204}
              tipo={"charts"}
              campo={1}
              giorni={props.days}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>Temperatura interna</h3>
            <Grafico
              id="incubatore-temperatura-esterna"
              canale={1578204}
              tipo={"charts"}
              campo={2}
              giorni={props.days}
              width="450"
              height="260"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
