import { Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { SommarioRow } from "./Sommario/SommarioRow";
import { setDownloadActive } from "../features/sommario/sommarioSlice";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

export function Sommario(props) {
  const dispatch = useDispatch();
  const dePieriLorentz = useSelector((state) => state.sommario.dePieriLorentz);
  const dePieriPedrollo = useSelector(
    (state) => state.sommario.dePieriPedrollo
  );
  const dePieriFotovoltaico = useSelector(
    (state) => state.sommario.dePieriFotovoltaico
  );
  const verzinoVentoNS = useSelector((state) => state.sommario.verzinoVentoNS);
  const verzinoVentoEO = useSelector((state) => state.sommario.verzinoVentoEO);
  const boieroEssiccatoreTemperatura = useSelector(
    (state) => state.sommario.boieroEssiccatoreTemperatura
  );
  const boieroEssiccatoreUmidita = useSelector(
    (state) => state.sommario.boieroEssiccatoreUmidita
  );
  const boieroFrigoSolare = useSelector(
    (state) => state.sommario.boieroFrigoSolare
  );
  const boieroContapassaggi1 = useSelector(
    (state) => state.sommario.boieroContapassaggi1
  );
  const boieroContapassaggi2 = useSelector(
    (state) => state.sommario.boieroContapassaggi2
  );
  const dePieriPedrolloMSP = useSelector(
    (state) => state.sommario.dePieriPedrolloMSP
  );
  const dePieriVaschePesci = useSelector(
    (state) => state.sommario.dePieriVaschePesci
  );
  const farmaci = useSelector((state) => state.sommario.farmaci);

  const year = useSelector((state) => state.sommario.year);
  const downloadActive = useSelector((state) => state.sommario.downloadActive);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const activeSensors = [
    year,
    dePieriLorentz,
    dePieriPedrollo,
    dePieriFotovoltaico,
    verzinoVentoNS,
    verzinoVentoEO,
    farmaci,
    boieroEssiccatoreTemperatura,
    boieroEssiccatoreUmidita,
    boieroFrigoSolare,
    boieroContapassaggi1,
    boieroContapassaggi2,
    dePieriPedrolloMSP,
    dePieriVaschePesci,
  ];
  const [csvData, setCsvData] = useState([]);

  function renderMeasurePerMonths(variable, unit, decimals) {
    return (
      <>
        {year === currentYear && (
          <>
            <td>
              {variable && variable.last7Days
                ? variable.last7Days.toFixed(decimals).toString() + ` ${unit}`
                : ""}
            </td>
            <td>
              {variable && variable.last30Days
                ? variable.last30Days.toFixed(decimals).toString() + ` ${unit}`
                : ""}
            </td>
          </>
        )}

        {variable && variable.months
          ? variable.months.map((hours) => (
              <td>
                {hours ? hours.toFixed(decimals).toString() + ` ${unit}` : ""}
              </td>
            ))
          : [...Array(12).map((k) => <td> "" </td>)]}
      </>
    );
  }

  function generateCSVDataPerMonths(variable, decimals) {
    if (!variable) return [];

    return [
      variable.last7Days ? variable.last7Days.toFixed(decimals).toString() : "",
      variable.last30Days
        ? variable.last30Days.toFixed(decimals).toString()
        : "",
      ...(variable.months
        ? variable.months.map((hours) =>
            hours ? hours.toFixed(decimals).toString() : ""
          )
        : []),
    ];
  }

  useEffect(() => {
    let currentData = [
      [
        "Nazione",
        "Località",
        "Oggetto",
        "Descrizione",
        "Referente",
        "Misura",
        "U.d.M.",
        "Ultimi 7 giorni",
        "Ultimi 30 giorni",
        `Gennaio ${year}`,
        `Febbraio ${year}`,
        `Marzo ${year}`,
        `Aprile ${year}`,
        `Maggio ${year}`,
        `Giugno ${year}`,
        `Luglio ${year}`,
        `Agosto ${year}`,
        `Settembre ${year}`,
        `Ottobre ${year}`,
        `Novembre ${year}`,
        `Dicembre ${year}`,
      ],
      [
        "Guinea Bissau",
        "Ntchangue",
        "Pompaggio",
        "Pompa Lorentz",
        "De Pieri",
        "Ore funzionamento",
        "ore (h)",
        ...generateCSVDataPerMonths(dePieriLorentz, 2),
      ],
      [
        "Guinea Bissau",
        "Ntchangue",
        "Pompaggio",
        "Pompa Pedrollo",
        "De Pieri",
        "Ore funzionamento",
        "ore (h)",
        ...generateCSVDataPerMonths(dePieriPedrollo, 2),
      ],
      [
        "Tanzania",
        "Mkiu",
        "Impianto fotovoltaico",
        "Impianto fotovoltaico 12kW",
        "De Pieri",
        "Ore funzionamento",
        "ore (h)",
        ...generateCSVDataPerMonths(dePieriFotovoltaico, 2),
      ],
      [
        "Georgia",
        "Kvareli",
        "Caseificio",
        "???",
        "Favini",
        "Ore funzionamento",
        "ore (h)",
      ],
      ["Botswana", "", "Arnia #1", "HiveMonitoring", "Falco", "Peso", "Kg"],
      ["Botswana", "", "Arnia #2", "HiveMonitoring", "Falco", "Peso", "Kg"],
      ["Botswana", "", "Arnia #3", "HiveMonitoring", "Falco", "Peso", "Kg"],
      [
        "Guinea Bissau",
        "",
        "Stazione meteo",
        "Vento Nord-Sud MAX",
        "Verzino",
        "Velocità",
        "m/s",
        ...generateCSVDataPerMonths(verzinoVentoNS, 2),
      ],
      [
        "Guinea Bissau",
        "",
        "Stazione meteo",
        "Vento Est-Ovest MAX",
        "Verzino",
        "Velocità",
        "m/s",
        ...generateCSVDataPerMonths(verzinoVentoEO, 2),
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Farmaci",
        "Spedizione e disteribuzione nelle varie località",
        "Turetti",
        "Scatole",
        "n°",
        ...(farmaci ? generateCSVDataPerMonths(farmaci["Piscina"], 0) : []),
      ],
      [
        "Italia",
        "Sangano",
        "Farmaci",
        "Spedizione e disteribuzione nelle varie località",
        "Turetti",
        "Scatole",
        "n°",
        ...(farmaci ? generateCSVDataPerMonths(farmaci["Sangano"], 0) : []),
      ],
      [
        "Italia",
        "Carmagnola",
        "Farmaci",
        "Spedizione e disteribuzione nelle varie località",
        "Turetti",
        "Scatole",
        "n°",
        ...(farmaci ? generateCSVDataPerMonths(farmaci["Carmagnola"], 0) : []),
      ],
      [
        "Romania",
        "Baia Mare",
        "Farmaci",
        "Spedizione e disteribuzione nelle varie località",
        "Turetti",
        "Scatole",
        "n°",
        ...(farmaci ? generateCSVDataPerMonths(farmaci["Baia Mare"], 0) : []),
      ],
      [
        "Romania",
        "Butea",
        "Farmaci",
        "Spedizione e disteribuzione nelle varie località",
        "Turetti",
        "Scatole",
        "n°",
        ...(farmaci ? generateCSVDataPerMonths(farmaci["Butea"], 0) : []),
      ],
      [
        "Vari",
        "Transito",
        "Farmaci",
        "Spedizione e disteribuzione nelle varie località",
        "Turetti",
        "Scatole",
        "n°",
        ...(farmaci ? generateCSVDataPerMonths(farmaci["Transito"], 0) : []),
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Idroponia",
        "Consumo acqua - Berny1",
        "Boiero",
        "Consumo acqua",
        "litri/mese",
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Idroponia",
        "Consumo acqua - Berny2",
        "Boiero",
        "Consumo acqua",
        "litri/mese",
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Idroponia",
        "Consumo acqua - Paolina totale",
        "Boiero",
        "Consumo acqua",
        "litri/mese",
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Idroponia",
        "Consumo acqua - Lumacaio",
        "Boiero",
        "Consumo acqua",
        "litri/mese",
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Eoliana",
        "Vevor",
        "Verzino",
        "Potenza prodotta",
        "kWh",
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Essiccatore",
        "Temperatura massima raggiunta interna",
        "Boiero",
        "Temperatura",
        "°C",
        ...generateCSVDataPerMonths(boieroEssiccatoreTemperatura, 2),
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Essiccatore",
        "Temperatura massima raggiunta interna",
        "Boiero",
        "Umidità",
        "%",
        ...generateCSVDataPerMonths(boieroEssiccatoreUmidita, 2),
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Frigo Solare",
        "Compressore Melform + accumualtori Fe-Li",
        "Boiero",
        "Tempo azionamento compressore",
        "ore (h)",
        ...generateCSVDataPerMonths(boieroFrigoSolare, 2),
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Contapassaggi",
        "Contapassaggi ingresso 1",
        "Boiero",
        "Passaggi",
        "n°",
        ...generateCSVDataPerMonths(boieroContapassaggi1, 0),
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Contapassaggi",
        "Contapassaggi ingresso 2",
        "Boiero",
        "Passaggi",
        "n°",
        ...generateCSVDataPerMonths(boieroContapassaggi2, 0),
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Pompaggio",
        "Pompa di Lino",
        "Boiero",
        "Ore funzionamento",
        "ore (h)",
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Incubatrice",
        "Temperatura massima raggiunta interna",
        "Eligio",
        "Temperatura",
        "°C",
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Incubatrice",
        "Umidità",
        "Eligio",
        "Umidità",
        "%",
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Pompaggio",
        "Pompa Pedrollo con MSP",
        "De Pieri",
        "Ore funzionamento",
        "ore (h)",
        ...generateCSVDataPerMonths(dePieriPedrolloMSP, 2),
      ],
      [
        "Italia",
        "Villaggio Globale",
        "Pompaggio",
        "Pompa vasche pesci",
        "De Pieri",
        "Ore funzionamento",
        "ore (h)",
        ...generateCSVDataPerMonths(dePieriVaschePesci, 2),
      ],
      [
        "Italia",
        "Piossasco",
        "Orto del Marchile",
        "Cella Frigo (QUALE?)",
        "De Pieri",
        "Ore funzionamento",
        "ore (h)",
      ],
      [
        "Italia",
        "Piossasco",
        "Orto del Marchile",
        "Cella Frigo (QUALE?)",
        "De Pieri",
        "Temperatura massima interna",
        "°C",
      ],
    ];
    setCsvData(currentData);

    if (activeSensors.every((elem) => elem)) dispatch(setDownloadActive(true));
  }, [
    year,
    dePieriLorentz,
    dePieriPedrollo,
    dePieriFotovoltaico,
    verzinoVentoNS,
    verzinoVentoEO,
    farmaci,
    boieroEssiccatoreTemperatura,
    boieroEssiccatoreUmidita,
    boieroFrigoSolare,
    boieroContapassaggi1,
    boieroContapassaggi2,
    dePieriPedrolloMSP,
    dePieriVaschePesci,
  ]);

  return (
    <section id="tabella-riassuntiva" className="thingspeak-plot-section">
      {/* Grafici/Dati del canale */}
      <h2>Tabella riassuntiva - Anno {year}</h2>
      <CSVLink
        data={csvData}
        filename={`monitoraggio_${year}.csv`}
        className={"btn btn-primary " + (downloadActive ? "" : "disabled")}
        target="_blank"
        disabled={!downloadActive}
      >
        {downloadActive ? `Download Excel ${year}` : "Caricamento..."}
      </CSVLink>
      <Table striped bordered hover className="tabella-sommario">
        <thead>
          <tr style={{ backgroundColor: "white" }}>
            <th>Nazione</th>
            <th>Località</th>
            <th>Oggetto</th>
            <th>Descrizione</th>
            <th>Referente</th>
            <th>Misura</th>
            <th>U.d.M.</th>
            {year === currentYear && <th>Ultimi 7 giorni</th>}
            {year === currentYear && <th>Ultimi 30 giorni</th>}
            <th>Gennaio {year}</th>
            <th>Febbraio {year}</th>
            <th>Marzo {year}</th>
            <th>Aprile {year}</th>
            <th>Maggio {year}</th>
            <th>Giugno {year}</th>
            <th>Luglio {year}</th>
            <th>Agosto {year}</th>
            <th>Settembre {year}</th>
            <th>Ottobre {year}</th>
            <th>Novembre {year}</th>
            <th>Dicembre {year}</th>
          </tr>
        </thead>
        <tbody>
          <SommarioRow
            country="Guinea Bissau"
            place="Ntchangue"
            sensor="Pompaggio"
            link="https://thingspeak.com/channels/879658"
            description="Pompa Lorentz"
            referent="De Pieri"
            measurement="Ore funzionamento"
            unitOfMeasurement="ore (h)"
            renderedData={renderMeasurePerMonths(dePieriLorentz, "h", 2)}
          />
          <SommarioRow
            country="Guinea Bissau"
            place="Ntchangue"
            sensor="Pompaggio"
            link="https://thingspeak.com/channels/1244762"
            description="Pompa Pedrollo"
            referent="De Pieri"
            measurement="Ore funzionamento"
            unitOfMeasurement="ore (h)"
            renderedData={renderMeasurePerMonths(dePieriPedrollo, "h", 2)}
          />
          <SommarioRow
            country="Tanzania"
            place="Mkiu"
            sensor="Impianto fotovoltaico"
            link="https://thingspeak.com/channels/1726924"
            description="Impianto fotovoltaico 12kW"
            referent="De Pieri"
            measurement="Ore funzionamento"
            unitOfMeasurement="ore (h)"
            renderedData={renderMeasurePerMonths(dePieriFotovoltaico, "h", 2)}
          />
          <SommarioRow
            country="Georgia"
            place="Kvareli"
            sensor="Caseificio"
            link="#"
            description="???"
            referent="Favini"
            measurement="Ore funzionamento"
            unitOfMeasurement="ore (h)"
            renderedData={
              <td
                colSpan={year === currentYear ? 14 : 12}
                style={{ backgroundColor: "red", color: "white" }}
              >
                Dove vengono trasmessi i dati???
              </td>
            }
          />
          <SommarioRow
            country="Botswana"
            place=""
            sensor="Arnia #1"
            link="https://www.beehivemonitoring.com"
            description="HiveMonitoring"
            referent="Falco"
            measurement="Peso"
            unitOfMeasurement="Kg"
            renderedData={
              <td
                colSpan={year === currentYear ? 14 : 12}
                style={{ backgroundColor: "red", color: "white" }}
              >
                Dove vengono trasmessi i dati???
              </td>
            }
          />
          <SommarioRow
            country="Botswana"
            place=""
            sensor="Arnia #2"
            link="https://www.beehivemonitoring.com"
            description="HiveMonitoring"
            referent="Falco"
            measurement="Peso"
            unitOfMeasurement="Kg"
            renderedData={
              <td
                colSpan={year === currentYear ? 14 : 12}
                style={{ backgroundColor: "black", color: "white" }}
              >
                NON ATTIVA
              </td>
            }
          />
          <SommarioRow
            country="Botswana"
            place=""
            sensor="Arnia #3"
            link="https://www.beehivemonitoring.com"
            description="HiveMonitoring"
            referent="Falco"
            measurement="Peso"
            unitOfMeasurement="Kg"
            renderedData={
              <td
                colSpan={year === currentYear ? 14 : 12}
                style={{ backgroundColor: "black", color: "white" }}
              >
                NON ATTIVA
              </td>
            }
          />
          <SommarioRow
            country="Guinea Bissau"
            place=""
            sensor="Stazione meteo"
            link="https://thingspeak.com/channels/1570323"
            description="Vento Nord-Sud MAX"
            referent="Verzino"
            measurement="Velocità"
            unitOfMeasurement="m/s"
            renderedData={renderMeasurePerMonths(verzinoVentoNS, "m/s", 2)}
          />
          <SommarioRow
            country="Guinea Bissau"
            place=""
            sensor="Stazione meteo"
            link="https://thingspeak.com/channels/1570323"
            description="Vento Est-Ovest MAX"
            referent="Verzino"
            measurement="Velocità"
            unitOfMeasurement="m/s"
            renderedData={renderMeasurePerMonths(verzinoVentoEO, "m/s", 2)}
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Farmaci"
            link="#"
            description="Spedizione e disteribuzione nelle varie località"
            referent="Turetti"
            measurement="Scatole"
            unitOfMeasurement="n°"
            renderedData={
              farmaci ? (
                renderMeasurePerMonths(farmaci["Piscina"], "", 0)
              ) : (
                <td
                  colSpan={year === currentYear ? 14 : 12}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Dove vengono trasmessi i dati???
                </td>
              )
            }
          />
          <SommarioRow
            country="Italia"
            place="Sangano"
            sensor="Farmaci"
            link="#"
            description="Spedizione e disteribuzione nelle varie località"
            referent="Turetti"
            measurement="Scatole"
            unitOfMeasurement="n°"
            renderedData={
              farmaci ? (
                renderMeasurePerMonths(farmaci["Sangano"], "", 0)
              ) : (
                <td
                  colSpan={year === currentYear ? 14 : 12}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Dove vengono trasmessi i dati???
                </td>
              )
            }
          />
          <SommarioRow
            country="Italia"
            place="Carmagnola"
            sensor="Farmaci"
            link="#"
            description="Spedizione e disteribuzione nelle varie località"
            referent="Turetti"
            measurement="Scatole"
            unitOfMeasurement="n°"
            renderedData={
              farmaci ? (
                renderMeasurePerMonths(farmaci["Carmagnola"], "", 0)
              ) : (
                <td
                  colSpan={year === currentYear ? 14 : 12}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Dove vengono trasmessi i dati???
                </td>
              )
            }
          />
          <SommarioRow
            country="Romania"
            place="Baia Mare"
            sensor="Farmaci"
            link="#"
            description="Spedizione e disteribuzione nelle varie località"
            referent="Turetti"
            measurement="Scatole"
            unitOfMeasurement="n°"
            renderedData={
              farmaci ? (
                renderMeasurePerMonths(farmaci["Baia Mare"], "", 0)
              ) : (
                <td
                  colSpan={year === currentYear ? 14 : 12}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Dove vengono trasmessi i dati???
                </td>
              )
            }
          />
          <SommarioRow
            country="Romania"
            place="Butea"
            sensor="Farmaci"
            link="#"
            description="Spedizione e disteribuzione nelle varie località"
            referent="Turetti"
            measurement="Scatole"
            unitOfMeasurement="n°"
            renderedData={
              farmaci ? (
                renderMeasurePerMonths(farmaci["Butea"], "", 0)
              ) : (
                <td
                  colSpan={year === currentYear ? 14 : 12}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Dove vengono trasmessi i dati???
                </td>
              )
            }
          />
          <SommarioRow
            country="Vari"
            place="Transito"
            sensor="Farmaci"
            link="#"
            description="Spedizione e disteribuzione nelle varie località"
            referent="Turetti"
            measurement="Scatole"
            unitOfMeasurement="n°"
            renderedData={
              farmaci ? (
                renderMeasurePerMonths(farmaci["Transito"], "", 0)
              ) : (
                <td
                  colSpan={year === currentYear ? 14 : 12}
                  style={{ backgroundColor: "red", color: "white" }}
                >
                  Dove vengono trasmessi i dati???
                </td>
              )
            }
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Idroponia"
            link="#"
            description="Consumo acqua - Berny1"
            referent="Boiero"
            measurement="Consumo acqua"
            unitOfMeasurement="litri/mese"
            renderedData={
              <td
                colSpan={year === currentYear ? 14 : 12}
                style={{ backgroundColor: "red", color: "white" }}
              >
                Dove vengono trasmessi i dati???
              </td>
            }
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Idroponia"
            link="#"
            description="Consumo acqua - Berny2"
            referent="Boiero"
            measurement="Consumo acqua"
            unitOfMeasurement="litri/mese"
            renderedData={
              <td
                colSpan={year === currentYear ? 14 : 12}
                style={{ backgroundColor: "red", color: "white" }}
              >
                Dove vengono trasmessi i dati???
              </td>
            }
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Idroponia"
            link="#"
            description="Consumo acqua - Paolina totale"
            referent="Boiero"
            measurement="Consumo acqua"
            unitOfMeasurement="litri/mese"
            renderedData={
              <td
                colSpan={year === currentYear ? 14 : 12}
                style={{ backgroundColor: "red", color: "white" }}
              >
                Dove vengono trasmessi i dati???
              </td>
            }
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Idroponia"
            link="#"
            description="Consumo acqua - Lumacaio"
            referent="Boiero"
            measurement="Consumo acqua"
            unitOfMeasurement="litri/mese"
            renderedData={
              <td
                colSpan={year === currentYear ? 14 : 12}
                style={{ backgroundColor: "red", color: "white" }}
              >
                Dove vengono trasmessi i dati???
              </td>
            }
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Eoliana"
            link="#"
            description="Vevor"
            referent="Verzino"
            measurement="Potenza prodotta"
            unitOfMeasurement="kWh"
            renderedData={
              <td
                colSpan={year === currentYear ? 14 : 12}
                style={{ backgroundColor: "black", color: "white" }}
              >
                NON ATTIVA
              </td>
            }
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Essiccatore"
            link="https://thingspeak.com/channels/1612409"
            description="Temperatura massima raggiunta interna"
            referent="Boiero"
            measurement="Temperatura"
            unitOfMeasurement="°C"
            renderedData={renderMeasurePerMonths(
              boieroEssiccatoreTemperatura,
              "°C",
              2
            )}
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Essiccatore"
            link="https://thingspeak.com/channels/1612409"
            description="Umidità massima (???)"
            referent="Boiero"
            measurement="Umidità"
            unitOfMeasurement="%"
            renderedData={renderMeasurePerMonths(
              boieroEssiccatoreUmidita,
              "%",
              2
            )}
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Frigo Solare"
            link="https://thingspeak.com/channels/1612409"
            description="Compressore Melform + accumualtori Fe-Li"
            referent="Boiero"
            measurement="Tempo azionamento compressore"
            unitOfMeasurement="ore (h)"
            renderedData={renderMeasurePerMonths(boieroFrigoSolare, "h", 2)}
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Contapassaggi"
            link="https://thingspeak.com/channels/1612407"
            description="Contapassaggi ingresso 1"
            referent="Boiero"
            measurement="Passaggi"
            unitOfMeasurement="n°"
            renderedData={renderMeasurePerMonths(boieroContapassaggi1, "n°", 0)}
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Contapassaggi"
            link="https://thingspeak.com/channels/1612407"
            description="Contapassaggi ingresso 2"
            referent="Boiero"
            measurement="Passaggi"
            unitOfMeasurement="n°"
            renderedData={renderMeasurePerMonths(boieroContapassaggi2, "n°", 0)}
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Pompaggio"
            link="#"
            description="Pompa di Lino"
            referent="Boiero"
            measurement="Ore funzionamento"
            unitOfMeasurement="ore (h)"
            renderedData={
              <td
                colSpan={year === currentYear ? 14 : 12}
                style={{ backgroundColor: "black", color: "white" }}
              >
                NON ATTIVA
              </td>
            }
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Incubatrice"
            link="#"
            description="Temperatura massima raggiunta interna"
            referent="Eligio"
            measurement="Temperatura"
            unitOfMeasurement="°C"
            renderedData={
              <td
                colSpan={year === currentYear ? 14 : 12}
                style={{ backgroundColor: "black", color: "white" }}
              >
                NON ATTIVA
              </td>
            }
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Incubatrice"
            link="#"
            description="Umidità"
            referent="Eligio"
            measurement="Umidità"
            unitOfMeasurement="%"
            renderedData={
              <td
                colSpan={year === currentYear ? 14 : 12}
                style={{ backgroundColor: "black", color: "white" }}
              >
                NON ATTIVA
              </td>
            }
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Pompaggio"
            link="https://thingspeak.com/channels/896318"
            description="Pompa Pedrollo con MSP"
            referent="De Pieri"
            measurement="Ore funzionamento"
            unitOfMeasurement="ore (h)"
            renderedData={renderMeasurePerMonths(dePieriPedrolloMSP, "h", 2)}
          />
          <SommarioRow
            country="Italia"
            place="Villaggio Globale"
            sensor="Pompaggio"
            link="https://thingspeak.com/channels/2051259"
            description="Pompa vasche pesci"
            referent="De Pieri"
            measurement="Ore funzionamento"
            unitOfMeasurement="ore (h)"
            renderedData={renderMeasurePerMonths(dePieriVaschePesci, "h", 2)}
          />
          <SommarioRow
            country="Italia"
            place="Piossasco"
            sensor="Orto del Marchile"
            link="#"
            description="Cella Frigo (QUALE?)"
            referent="De Pieri"
            measurement="Ore funzionamento"
            unitOfMeasurement="ore (h)"
            renderedData={
              <td
                colSpan={year === currentYear ? 14 : 12}
                style={{ backgroundColor: "black", color: "white" }}
              >
                NON ATTIVA
              </td>
            }
          />
          <SommarioRow
            country="Italia"
            place="Piossasco"
            sensor="Orto del Marchile"
            link="#"
            description="Cella Frigo (QUALE?)"
            referent="De Pieri"
            measurement="Temperatura massima interna"
            unitOfMeasurement="°C"
            renderedData={
              <td
                colSpan={year === currentYear ? 14 : 12}
                style={{ backgroundColor: "black", color: "white" }}
              >
                NON ATTIVA
              </td>
            }
          />
        </tbody>
      </Table>
    </section>
  );
}
