export function SommarioRow(props) {
  return (
    <tr>
      <td>{props.country}</td>
      <td>{props.place}</td>
      <td>{props.sensor}</td>
      <td>
        <a href={props.link}>{props.description}</a>
      </td>
      <td>{props.referent}</td>
      <td>{props.measurement}</td>
      <td>{props.unitOfMeasurement}</td>
      {props.renderedData}
    </tr>
  );
}
