import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import { MenuItem } from "./MenuItem";
import { Slider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setYear } from "../features/sommario/sommarioSlice";
import { downloadThingSpeakData } from "../features/sommario/sommarioMethods";
import { useEffect, useRef } from "react";

export function NavBar(props) {
  const dispatch = useDispatch();
  const year = useSelector((state) => state.sommario.year);
  const abortControllerRef = useRef(null);

  function showYearOptions() {
    const currentYear = new Date().getFullYear();
    let ret = [];

    for (let showYear = 2022; showYear <= currentYear; showYear++)
      ret.push(<option value={showYear}>{showYear}</option>);

    return ret;
  }

  useEffect(() => {
    dispatch(downloadThingSpeakData(year, abortControllerRef));
  }, [dispatch, year]);

  return (
    <Navbar bg="dark" expand="lg" variant={"dark"}>
      <Container>
        <Navbar.Brand href="#home">Villaggio Globale di Cumiana</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" style={{ width: "20px" }}>
          <Nav className="me-auto">
            {/* Menu sensori */}
            <NavDropdown title="Sensori" id="basic-nav-dropdown">
              <NavDropdown.Item>
                <MenuItem
                  name="Sommario"
                  id="menu-sommario"
                  setShowComponent={props.setShowComponent}
                />
              </NavDropdown.Item>
              <NavDropdown.Item>
                <MenuItem
                  name="Incubatore solare"
                  id="menu-incubatore"
                  setShowComponent={props.setShowComponent}
                />
              </NavDropdown.Item>
              <NavDropdown.Item>
                <MenuItem
                  name="Contapersone"
                  id="menu-contapersone"
                  setShowComponent={props.setShowComponent}
                />
              </NavDropdown.Item>
              <NavDropdown.Item>
                <MenuItem
                  name="Pompa Lorentz"
                  id="menu-pompa-lorentz"
                  setShowComponent={props.setShowComponent}
                />
              </NavDropdown.Item>
              <NavDropdown.Item>
                <MenuItem
                  name="Pompa Pedrollo"
                  id="menu-pompa-pedrollo"
                  setShowComponent={props.setShowComponent}
                />
              </NavDropdown.Item>
              <NavDropdown.Item>
                <MenuItem
                  name="Pompa Pedrollo Cumiana"
                  id="menu-pompa-pedrollo-cumiana"
                  setShowComponent={props.setShowComponent}
                />
              </NavDropdown.Item>
              <NavDropdown.Item>
                <MenuItem
                  name="Impianto Fotovoltaico Tanzania"
                  id="menu-impianto-fotovoltaico-tanzania"
                  setShowComponent={props.setShowComponent}
                />
              </NavDropdown.Item>
              {/*<NavDropdown.Item><MenuItem name="Essiccatore Solare" id="menu-essiccatore-solare" setShowComponent={props.setShowComponent}/></NavDropdown.Item>*/}
              <NavDropdown.Item>
                <MenuItem
                  name="Frigo Solare"
                  id="menu-frigo-solare"
                  setShowComponent={props.setShowComponent}
                />
              </NavDropdown.Item>
            </NavDropdown>

            {/* Menu opzioni */}
            <NavDropdown title="Opzioni" id="basic-nav-dropdown">
              <NavDropdown.ItemText style={{ "min-width": "20em" }}>
                Giorni da visualizzare: <br />
                <Slider
                  aria-label="Giorni da visualizzare"
                  defaultValue={3}
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={10}
                  onChange={(e) => {
                    props.setPlotDays(e.target.value);
                  }}
                />
              </NavDropdown.ItemText>

              <NavDropdown.ItemText style={{ "min-width": "20em" }}>
                Anno: <br />
                <Form.Select
                  aria-label="Anno"
                  defaultValue={year}
                  onChange={(e) => {
                    const newYear = Number(e.target.value);
                    dispatch(setYear(Number(newYear)));
                    dispatch(
                      downloadThingSpeakData(newYear, abortControllerRef)
                    );
                  }}
                >
                  {showYearOptions()}
                </Form.Select>
              </NavDropdown.ItemText>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
