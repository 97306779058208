import { createSlice } from "@reduxjs/toolkit";

export const sommarioSlice = createSlice({
  name: "sommario",
  initialState: {
    year: new Date().getFullYear(), // Get current year
    downloadActive: false,
    dePieriLorentz: null,
    dePieriPedrollo: null,
    dePieriFotovoltaico: null,
    verzinoVentoNS: null,
    verzinoVentoEO: null,
    boieroEssiccatoreTemperatura: null,
    boieroEssiccatoreUmidita: null,
    boieroFrigoSolare: null,
    boieroContapassaggi1: null,
    boieroContapassaggi2: null,
    dePieriPedrolloMSP: null,
    dePieriVaschePesci: null,
    farmaci: null,
  },
  reducers: {
    setYear: (state, newValue) => {
      state.year = newValue.payload;
    },
    setDownloadActive: (state, newValue) => {
      state.downloadActive = newValue.payload;
    },
    setLorentz: (state, newValue) => {
      state.dePieriLorentz = newValue.payload;
    },
    setPedrollo: (state, newValue) => {
      state.dePieriPedrollo = newValue.payload;
    },
    setFotovoltaico: (state, newValue) => {
      state.dePieriFotovoltaico = newValue.payload;
    },
    setVentoNS: (state, newValue) => {
      state.verzinoVentoNS = newValue.payload;
    },
    setVentoEO: (state, newValue) => {
      state.verzinoVentoEO = newValue.payload;
    },
    setBoieroEssiccatoreTemperatura: (state, newValue) => {
      state.boieroEssiccatoreTemperatura = newValue.payload;
    },
    setBoieroEssiccatoreUmidita: (state, newValue) => {
      state.boieroEssiccatoreUmidita = newValue.payload;
    },
    setBoieroFrigoSolare: (state, newValue) => {
      state.boieroFrigoSolare = newValue.payload;
    },
    setBoieroContapassaggi1: (state, newValue) => {
      state.boieroContapassaggi1 = newValue.payload;
    },
    setBoieroContapassaggi2: (state, newValue) => {
      state.boieroContapassaggi2 = newValue.payload;
    },
    setPedrolloMSP: (state, newValue) => {
      state.dePieriPedrolloMSP = newValue.payload;
    },
    setVaschePesci: (state, newValue) => {
      state.dePieriVaschePesci = newValue.payload;
    },
    setFarmaci: (state, newValue) => {
      state.farmaci = newValue.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setYear,
  setDownloadActive,
  setLorentz,
  setPedrollo,
  setFotovoltaico,
  setVentoNS,
  setVentoEO,
  setBoieroEssiccatoreTemperatura,
  setBoieroEssiccatoreUmidita,
  setBoieroFrigoSolare,
  setBoieroContapassaggi1,
  setBoieroContapassaggi2,
  setPedrolloMSP,
  setVaschePesci,
  setFarmaci,
} = sommarioSlice.actions;
export default sommarioSlice.reducer;
