export function Grafico(props) {
  function generaUrlGrafico(
    canale,
    tipo,
    campo,
    larghezza,
    altezza,
    giorni,
    formato
  ) {
    //FIXME: enable time interval (to be decided) in the form start=${inizio}&end=${fine} - See issue #1
    let generated_url = `https://thingspeak.com/channels/${canale}/${tipo}/${campo}/?width=${larghezza}&height=${altezza}`;

    if (giorni !== undefined) {
      generated_url += `&days=${giorni}`;
    }

    if (formato !== undefined) {
      generated_url += `&type=${formato}`;
    }

    console.log(`Retrieving ${generated_url}`);
    return generated_url;
  }

  // Same values as those used above in the html
  const larghezza = props.width;
  const altezza = props.height;
  const url_object = generaUrlGrafico(
    props.canale,
    props.tipo,
    props.campo,
    larghezza,
    altezza,
    props.giorni,
    props.formato
  );

  return (
    <object
      id={props.id}
      type="text/html"
      width={props.width}
      height={props.height}
      style={{ overflow: "auto", border: "1px ridge blue" }}
      data={url_object}
    />
  );
}
