import { Grafico } from "./Grafico";
import { MatlabVisualization } from "./MatlabVisualization";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export function PompaLorentz(props) {
  return (
    <section id="pompa-lorentz" className="thingspeak-plot-section">
      {/* Grafici/Dati del canale */}
      <h2>Pompa Lorentz</h2>

      <Container>
        <Row xs={1} lg={2}>
          <Col>
            <h3>
              Funzionamento
              <br />
            </h3>
            <Grafico
              id="pompa-lorentz-funzionamento"
              canale={879658}
              tipo={"charts"}
              campo={1}
              giorni={props.days}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>
              Misurazione
              <br />
            </h3>
            <Grafico
              id="pompa-lorentz-misurazione"
              canale={879658}
              tipo={"charts"}
              campo={2}
              giorni={props.days}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>
              Ultima lettura
              <br />
            </h3>
            <Grafico
              id="pompa-lorentz-ultima-lettura"
              canale={879658}
              tipo={"widgets"}
              campo={227424}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>
              Ore al giorno
              <br />
            </h3>
            <MatlabVisualization
              id="pompa-lorentz-ore-al-giorno"
              canale={444164}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>
              Funzionamento per settimana
              <br />
            </h3>
            <MatlabVisualization
              id="pompa-lorentz-per-settimana"
              canale={444166}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>
              Funzionamento ultimo mese
              <br />
            </h3>
            <MatlabVisualization
              id="pompa-lorentz-ultimo-mese"
              canale={444167}
              width="450"
              height="260"
            />
          </Col>

          <Col>
            <h3>
              Funzionamento ultimo anno
              <br />
            </h3>
            <MatlabVisualization
              id="pompa-lorentz-ultimo-anno"
              canale={444168}
              width="450"
              height="260"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
