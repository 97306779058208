import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Contapersone } from "./components/Contapersone";
import { PompaLorentz } from "./components/PompaLorentz";
import { PompaPedrollo } from "./components/PompaPedrollo";
import { useState } from "react";
import { PompaPedrolloCumiana } from "./components/PompaPedrolloCumiana";
import { ImpiantoFotovoltaicoTanzania } from "./components/ImpiantoFotovoltaicoTanzania";
import { NavBar } from "./components/NavBar";
import { Incubatore } from "./components/Incubatore";
// import { EssiccatoreSolare } from "./components/EssiccatoreSolare";
import { FrigoSolare } from "./components/FrigoSolare";
import { Sommario } from "./components/Sommario";

function App() {
  const [showComponent, setShowComponent] = useState("sommario");
  const [plotDays, setPlotDays] = useState(3);

  return (
    // Contenitore principale
    <div>
      <NavBar
        setShowComponent={setShowComponent}
        plotDays={plotDays}
        setPlotDays={setPlotDays}
      />

      {/* Sezioni dell'applicazione, per i vari dispositivi, mostrati con rendering condizionale
             in base allo stato selezionato da menu */}
      {/*{showComponent === 'idroponia' && <Idroponia/>}*/}
      {showComponent === "sommario" && <Sommario />}
      {showComponent === "incubatore" && <Incubatore days={plotDays} />}
      {showComponent === "contapersone" && <Contapersone days={plotDays} />}
      {showComponent === "pompa-lorentz" && <PompaLorentz days={plotDays} />}
      {showComponent === "pompa-pedrollo" && <PompaPedrollo days={plotDays} />}
      {showComponent === "pompa-pedrollo-cumiana" && (
        <PompaPedrolloCumiana days={plotDays} />
      )}
      {showComponent === "impianto-fotovoltaico-tanzania" && (
        <ImpiantoFotovoltaicoTanzania days={plotDays} />
      )}
      {/*{showComponent === 'essiccatore-solare' && <EssiccatoreSolare days={plotDays}/>}*/}
      {showComponent === "frigo-solare" && <FrigoSolare days={plotDays} />}

      {/* End smartphone / tablet look */}
    </div>
  );
}

export default App;
